import { FNAME_TO_FULLNAME_COUNTRY } from './constants'

function INPUT_LABEL({ isRequired = false, type = 'country' } = {}) {
  const labels = {
    country() {
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ this.countryRegionLabel }`
      }
      return `*${ this.countryRegionLabel }`
    },
    fname() {
      const { fname } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ fname }`
      }
      return `*${ fname }`
    },
    // 存在假必填
    lname: `*${ this.lang.label.lname }`,
    middleName: `*${ this.lang.label.middleName }`,
    fullName() {
      const labelText = this.countryId == 209 ? '姓名' : this.lang.label.fullname
      if (this.isAddressCheckRuleGray) {
        return `${ this.fieldRequired.fname ? '*' : '' }${ labelText }`
      }
      return `*${ labelText }`
    },
    fatherName() {
      const { fatherName } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ fatherName }`
      }
      return `*${ fatherName }`
    },
    englishName() {
      const { englishName } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ englishName }`
      }
      return `${ this.countryId == 178 ? '*' : '' }${ englishName }`
    },
    state() {
      const { label: { state }, other: { optional } } = this.lang
      if (this.isAddressCheckRuleGray) {
        return isRequired ? `*${ state }` : `${ state }(${ optional })`
      }
      return this.stateLabel
    },
    city() {
      const { city_suburb, suburb, city_municipality, city  } = this.lang.label
      const labelMap = {
        153: city_suburb,
        13: suburb,
        170: city_municipality
      }
      const labelText = labelMap[this.countryId] || city
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ labelText }`
      }
      return `*${ labelText }`
    },
    district() {
      const { neighborhood, district_ph, district } = this.lang.label
      const labelMap = {
        30: neighborhood,
        170: district_ph
      }
      const labelText = labelMap[this.countryId] || district
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ labelText }`
      }
      return labelText
    },
    street() {
      const { 
        label: { street_in, street_qa, street },
        other: { optional }
      } = this.lang
      const labelMap = {
        100: street_in,
        175: street_qa
      }
      const labelText = labelMap[this.countryId] || street
      if (this.isAddressCheckRuleGray) {
        return isRequired ? `*${ labelText }` : `${ labelText }${ optional }`
      }
      return (this.countryId == 100) ? `${ labelText }${ optional }` : `*${ labelText }`
    },
    taxNumber() {
      const { tax_number, tax_number_cl, tax_number_bm, tax_number_id, tax_number_63 } = this.lang.label
      const array = {
        30: tax_number,
        43: tax_number_cl,
        101: tax_number_id,
        63: tax_number_63
      }
      const labelText = array[+this.countryId] || tax_number_bm
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ labelText }`
      }
      return `*${ labelText }`
    },
    address1() {
      const { 
        address1_de, address1_in, address1_tw,
        address1_qa, address1, street_address
      } = this.lang.label
      const labelMap = {
        82: address1_de,
        100: address1_in,
        209: address1_tw,
        150: address1_de,
        175: address1_qa,
        172: street_address,
        128: address1_in
      }
      let labelText = labelMap[this.countryId] || address1
      if (this.hideAddress2.indexOf(this.countryId) > -1) {
        labelText = street_address
      }
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ labelText }`
      }
      return `*${ labelText }`
    },
    address2() {
      const {
        address2_de, address2_in, address2_tw, address2_qa,
        address2_br, address2_th, address2, address2_pl
      } = this.lang.label
      const labelMap = {
        82: address2_de,
        150: address2_de,
        100: address2_in,
        209: address2_tw,
        175: address2_qa,
        30: address2_br,
        85: address2_de,
        212: address2_th,
        170: address2_th,
        172: address2_pl,
        128: address2_th
      }
      const labelText = labelMap[this.countryId] || address2
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ labelText }`
      }
      if ([82, 150, 100].includes(+this.countryId)) {
        return `*${ labelText }`
      }
      return labelText
    },
    postcode() {
      const { postcode } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ postcode }`
      }
      return (this.noCheckPostocde.indexOf(this.countryId) == -1) ? `*${ postcode }` : postcode
    },
    tel() {
      const { tel } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ tel }`
      }
      return `*${ tel }`
    },
    standbyTel() {
      const { standby_tel } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ standby_tel }`
      }
      return standby_tel
    },
    nationalId() {
      const { label: { national_id, national_id_196 } } = this.lang
      const dataMap = {
        196: national_id_196
      }
      const label = dataMap[+this.countryId] || national_id
      if (this.isAddressCheckRuleGray) {
        return isRequired ? `*${ label }` : `${ label }`
      }
      return this.nationalIdLabel
    },
    zone() {
      const { zone } = this.lang.label
      if (this.isAddressCheckRuleGray) {
        return `${ this.fieldRequired.district ? '*' : '' }${ zone }`
      }
      return `*${ zone }`
    }
  }
  return labels[type]
}

function INPUT_LABEL_STORE({ isRequired = false, type = 'fname' } = {}) {
  const labels = {
    fname() {
      const { SHEIN_KEY_PWA_34775, SHEIN_KEY_PWA_28847 } = this.language
      const name = FNAME_TO_FULLNAME_COUNTRY.includes(Number(this.countryId))
        ? SHEIN_KEY_PWA_28847
        : SHEIN_KEY_PWA_34775
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ name }`
      }
      return `*${ name }`
    },
    lname() {
      const { SHEIN_KEY_PWA_15084 } = this.language
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ SHEIN_KEY_PWA_15084 }`
      }
      return `*${ SHEIN_KEY_PWA_15084 }`
    },
    fatherName() {
      const { SHEIN_KEY_PWA_15131 } = this.language
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ SHEIN_KEY_PWA_15131 }`
      }
      return `*${ SHEIN_KEY_PWA_15131 }`
    },
    englishName() {
      const { SHEIN_KEY_PWA_15946 } = this.language
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ SHEIN_KEY_PWA_15946 }`
      }
      return `*${ SHEIN_KEY_PWA_15946 }`
    },
    tel() {
      const { SHEIN_KEY_PWA_34785 } = this.language
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ SHEIN_KEY_PWA_34785 }`
      }
      return `*${ SHEIN_KEY_PWA_34785 }`
    },
    standbyTel() {
      const { SHEIN_KEY_PWA_34788 } = this.language
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ SHEIN_KEY_PWA_34788 }`
      }
      return SHEIN_KEY_PWA_34788
    },
    taxNumber() {
      const { SHEIN_KEY_PWA_27767 } = this.language
      if (this.isAddressCheckRuleGray) {
        return `${ isRequired ? '*' : '' }${ SHEIN_KEY_PWA_27767 }`
      }
      return SHEIN_KEY_PWA_27767
    }
  }
  return labels[type]
}

export {
  INPUT_LABEL,
  INPUT_LABEL_STORE
}
